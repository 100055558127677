import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  useDisclosure
} from '@chakra-ui/react';

export const LocatePop = ({ refresh, is_customer }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [showPopover, setShowPopover] = useState(false);
  
  const handlePermissionDenied = () => {
    const storageKey = 'lastOnOpenTimestamp';
    const lastTimestamp = localStorage.getItem(storageKey);
    const shouldCallOnOpen = !lastTimestamp || Date.now() - lastTimestamp > 35 * 60 * 60 * 1000;
  
    if (shouldCallOnOpen) {
      onOpen();
      localStorage.setItem(storageKey, Date.now());
    }
  };

  const requestLocationPermission = async () => {
    try {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('Location permission granted');
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            handlePermissionDenied();
          }
        }
      );
    } catch (error) {
      console.error('Error requesting location permission:', error);
    }
  };

  const sendLocationToBackend = async (position) => {
    const { latitude, longitude } = position.coords;
    let ite = { refresh };
    let rep = await fetch('https://api.prestigedelta.com/refreshtoken/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      },
      body: JSON.stringify(ite)
    });
    rep = await rep.json();
    let bab = rep.access_token;
   
    try {
      const response = await fetch('https://api.prestigedelta.com/updateuser/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `Bearer ${bab}`
        },
        body: JSON.stringify({ latitude, longitude, is_customer }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Location sent to backend:', data);
    } catch (error) {
      console.error('Error sending location to backend:', error);
    }
  };

  const trig = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        sendLocationToBackend(position);
        onClose();
      },
      (error) => {
        console.error('Error getting location:', error);
        onClose();
      }
    );
  };

  useEffect(() => {
    const checkLocationPermission = async () => {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state !== 'granted') {
          handlePermissionDenied();
        }
      });
    };
    onOpen();
    checkLocationPermission();
  }, []);

  return (
    <div>
      <ChakraProvider>
        <AlertDialog
          motionPreset='slideInBottom'
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader>Make it easier for customers to find you</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              Allow location access to help customers find your business more easily.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='green' onClick={trig} ml={3}>
                Allow
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </ChakraProvider>
    </div>
  );
};


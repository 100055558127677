import Framefirst from "./Framefirst.svg";



const ScreenLoad = () => {
    
    return(
    <body className="back">
    <div className="openu">
       <img src={Framefirst} className="frame" alt="logo"/>
    </div>
       
    </body>
    );
};
export default ScreenLoad;

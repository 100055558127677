import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker, InfoWindow, DirectionsRenderer } from '@react-google-maps/api';
import {
  ChakraProvider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Input,
  Button,
  Stack,
  Heading
} from '@chakra-ui/react';
import Select from 'react-select';


const containerStyle = {
  width: '100%',
  height: '100vh'
};

const defaultCenter = {
  lat: -34.397,
  lng: 150.644
};

const Hat = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
  const [map, setMap] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(defaultCenter);
  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [query, setQuery] = useState('');
  const [price, setPrice] = useState('');
  const [directions, setDirections] = useState(null);
  const [type, setType] = useState('item');
  const [message, setMessage] = useState('')
  const [newPosition, setNewPosition] = useState(null)

  const navigate = useNavigate();

  const optio = ['item', 'pack'];
    const opt = optio.map((p) => ({
      label: p,
      value: p,
    }));

    const handleInput = (input) => {
      setType(input)
    }
 
  const transformData = async (data) => {
    const icon = 'https://myprest.s3.eu-west-2.amazonaws.com/icart.jpeg'
    return data.map((item, index) => {
      if (item.latitude && item.longitude) {
        return {
          id: index + 1,
          name: item.business_name,
          icon: icon, // Use preloaded icon
          location: { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) },
          pack_price: parseFloat(item.pack_price),
          unit_price: parseFloat(item.unit_price),
          business_id: item.business_id,
          available_pack: item.packs_in_stock,
          available: item.units_in_stock,
          phone_no: (item.phone_number).replace('234', '0'),
          address: item.address
        };
      } else {
        // Handle geocoding
      }
    });
  };

  const onLoad = useCallback(function callback(map) {
    setMap(map);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setCurrentPosition(pos);
        map.panTo(pos);
      });
    }
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const fetchPlaces = async () => {
    let tok = JSON.parse(localStorage.getItem("user-info"));
    let refresh = tok?.refresh_token ?? 0;

    let rep = await fetch('https://api.prestigedelta.com/refreshtoken/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      },
      body: JSON.stringify({ refresh })
    });
    rep = await rep.json();
    let bab = rep.access_token;

    const response = await fetch(`https://api.prestigedelta.com/productsearch/?product_name=${query}&max_price=${price}&lng=${currentPosition.lng}&lat=${currentPosition.lat}&max_distance=1&quantity_type=${type.value}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${bab}`
      }
    });
    const data = await response.json();
    const filteredData = data.filter(place => place.latitude !== null && place.longitude !== null);
    const transformedData = await transformData(filteredData);
    setPlaces(transformedData);
    console.log(transformedData)
  };

  const handleShop = (place) => {
    // Redirect to the shop page with place data
    navigate('/components/shop', { state: { place } });
     console.log(place)
  };

  const handleSearch = () => {
    if (places === '' || price === '') {
        setMessage('All fields must be filled')
    }
    else{
    fetchPlaces();
    onClose();}
  };

  const handleShowDirections = () => {
    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: currentPosition,
        destination: selectedPlace.location,
        travelMode: window.google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  const handleMarkerDragEnd = (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    setCurrentPosition({ lat: newLat, lng: newLng });
    setNewPosition({ lat: newLat, lng: newLng });
    onConfirmOpen(); // Open the confirmation modal
  };
  const handleSaveLocation = async () => {
    let tok = JSON.parse(localStorage.getItem("user-info"));
    let refresh = tok?.refresh_token ?? 0;
    let is_customer = tok?.is_customer ?? 0;
  
    // Save the new position
    setCurrentPosition(newPosition);
    onConfirmClose();
  
    let latitude = newPosition.lat; // Use the new position
    let longitude = newPosition.lng;
  
    try {
      // Refresh the token
      let rep = await fetch('https://api.prestigedelta.com/refreshtoken/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
        },
        body: JSON.stringify({ refresh }),
      });
      rep = await rep.json();
      let bab = rep.access_token;
  
      // Send the new location to the backend
      const response = await fetch('https://api.prestigedelta.com/updateuser/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `Bearer ${bab}`,
        },
        body: JSON.stringify({ latitude, longitude, is_customer }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      console.log('Location sent to backend:', data);
    } catch (error) {
      console.error('Error sending location to backend:', error);
    }
  };
  
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentPosition({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error fetching the geolocation: ", error);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (map && places.length > 0) {
      map.panTo(currentPosition);
      setTimeout(() => {
        map.panTo(currentPosition);
      }, 100); // Allow time for map to process the new data
    }
  }, [places, map]);
  console.log(places)
  
  const libraries = ['places'];

  return (
    <ChakraProvider>
      <div>
        <Link to='/components/product'>
          <i className="fa-solid fa-chevron-left bac"></i>
        </Link>

        <LoadScript googleMapsApiKey="AIzaSyCP4llmkll6GKy5NZ9RdmR3-U5paXEi4ug" libraries={libraries}>
        <Heading fontSize='18px'>Find Nearby Products at the Best Prices</Heading>
          <Button colorScheme='green' mb={2} onClick={onOpen}>Search Product</Button>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={currentPosition}
            zoom={15}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
           {window.google && (
            <Marker
              position={currentPosition}
              draggable={true}
              onDragEnd={handleMarkerDragEnd}
              label={{
                text: "You",
                color: "blue",
                fontSize: "16px",
                fontWeight: "bold"
              }}
              icon={{
                url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                labelOrigin: new window.google.maps.Point(0, -20)
              }}
            />
             )}
             {places.map((place) => (
  <Marker
    key={place.business_id}
    title={place.name}
    label={{
      text: `₦${place.unit_price}`,
      color: "black",
      fontSize: "16px",
      fontWeight: "bold",
      labelOrigin: window.google ? new window.google.maps.Point(24, -10) : null
    }}
    icon={{
      url: place.icon,
      scaledSize: window.google ? new window.google.maps.Size(48, 48) : null,
      labelOrigin: window.google ? new window.google.maps.Point(24, -10) : null
    }}
    position={place.location}
    onClick={() => setSelectedPlace(place)}
  />
))}
            {directions && (
              <DirectionsRenderer directions={directions} />
            )}
            {selectedPlace && (
              <InfoWindow
                position={selectedPlace.location}
                onCloseClick={() => setSelectedPlace(null)}
              >
                <div>
                  <h2>{selectedPlace.name}</h2>
                  <p>
  Unit Price: ₦{selectedPlace.unit_price}, 
  <span style={{ color: selectedPlace.available < 5 ? 'red' : 'black' }}>
    {selectedPlace.available} left
  </span> available
</p>
<p>
  Pack Price: ₦{selectedPlace.pack_price}, 
  <span style={{ color: selectedPlace.available_pack < 5 ? 'red' : 'black' }}>
    {selectedPlace.available_pack} left
  </span>
</p>
                  <Stack direction='row' spacing={1} justify='center' mt={2}>
                    <Button colorScheme='green' onClick={() => handleShop(selectedPlace)}>Shop</Button>
                    <Button colorScheme='blue' onClick={handleShowDirections}>Direction</Button>
                  </Stack>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Search for Product</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                  <Input
                    type="text"
                    w={293}
                    size='md'
                    m={3}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Product Name"
                  /><br />
                  <Select
        onChange={handleInput}
        className='mop'
        placeholder="Quantity Type"
        options={opt}
        value={type} />
                  <Input
                    placeholder='Maximum Price'
                    w={293}
                    m={3}
                    size='md'
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  /><br /><br />
                  <Button colorScheme='blue' onClick={handleSearch}>Search</Button>
                    <p>{message}</p>
              </ModalBody>
            </ModalContent>
          </Modal>
          <Modal isOpen={isConfirmOpen} onClose={onConfirmClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Confirm Location</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                Do you want to save the current location as your new business location?
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={handleSaveLocation}>
                  Yes
                </Button>
                <Button onClick={onConfirmClose}>No</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </LoadScript>
      </div>
    </ChakraProvider>
  );
};

export default Hat;

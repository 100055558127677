import { ChakraProvider } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Card, CardHeader, Box, useDisclosure, Image, SimpleGrid, Button, Heading, Text, Input } from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import good from './images/good.svg';
import AWS from 'aws-sdk';

const ProDe = () =>{
    const [info, setInfo] = useState('')
    const [loading, setLoading] = useState(true)
    const { isOpen, onOpen,  onClose } = useDisclosure()
    const [messag, setMessag] = useState('')
    const [fun, setFun] = useState('')
    const [file, setFile] = useState(null);
  const [done, setDone] = useState('');
  const [buttonVisible, setButtonVisible] = useState(true);
  const [pack_price, setPack] = useState('')
  const [price, setPrice] = useState('')
  const [fan, setFin] = useState('')
  const [img, setImg] = useState('');
  const modal1 = useDisclosure()
  const modal2 = useDisclosure()
  const modal3 = useDisclosure()
    
    const navigate = useNavigate()
    const location = useLocation()
    let fin = location.state.data
    let index = fin.mata
  
    const accessKey = process.env.REACT_APP_access
    const secretKey = process.env.REACT_APP_id_aws_key
    
    
    
    let tok= JSON.parse(localStorage.getItem("user-info"));
const terms = (tok) => {
  let refreshval;
  if (tok === null || typeof tok === 'undefined') {
    refreshval = 0;
  } else {
    refreshval = tok.refresh_token;
  }
 
  return refreshval;
};

const handleClick = () => {
  // When the button is clicked, setButtonVisible to false
  setButtonVisible(false);
  setTimeout(() => {
    setButtonVisible(true);
  }, 20000);
};
const handleFileChange = (e) => {
  const file = e.target.files[0];
  setFile(file);
};
const handlePack = (e) => {
  setPack(e.target.value)
};
const handlePrice = (e) => {
    setPrice(e.target.value)
};


const uploadFile = async () => {
  try {
    handleClick(); // Ensure this function is defined elsewhere

    // S3 Bucket Name and Region
    const S3_BUCKET = "mywealthbucket";
    const REGION = "eu-west-2";
    
    // S3 Credentials
    AWS.config.update({
      accessKeyId: accessKey,
      secretAccessKey: secretKey,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    // File Parameters
    const params = {
      Bucket: S3_BUCKET,
      Key: file.name,
      Body: file,
    };

    // Uploading file to S3
    const upload = s3.upload(params);

    upload.on('httpUploadProgress', (evt) => {
      // File uploading progress
      console.log("Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%");
    });

    const data = await upload.promise();

    console.log("File uploaded successfully.", data);
    setDone("updated successfully, refresh page to view changes.");
    setImg(data.Location); // This will give you the URL of the uploaded file

  } catch (err) {
    console.error("File upload failed:", err);
    setDone("File upload failed.");
  }
};


const sent =()=>{
  const data = fin
     navigate('/components/productanayltic', {state:{data}})
}

let refresh = terms(tok)
console.log(index)
    const fetchData = async () => {
        let item ={refresh}
        let rep = await fetch ('https://api.prestigedelta.com/refreshtoken/',{
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              'accept' : 'application/json'
         },
         body:JSON.stringify(item)
        });
        
        rep = await rep.json();
        let bab = rep.access_token
      let response = await fetch("https://api.prestigedelta.com/products/",{
      method: "GET",
      headers:{'Authorization': `Bearer ${bab}`},
      })
      //localStorage.setItem('user-info', JSON.stringify(tok))
      
      if (response.status === 401) {
        navigate('/components/login');
      } else { 
       
      response = await response.json();
      setLoading(false)
      setInfo(response)
      
        }}
        useEffect(() => {
          fetchData()
        }, [])

        async function aprod() {
          
          let items ={refresh}
           let rep = await fetch ('https://api.prestigedelta.com/refreshtoken/',{
               method: 'POST',
               headers:{
                 'Content-Type': 'application/json',
                 'accept' : 'application/json'
            },
            body:JSON.stringify(items)
           });
           rep = await rep.json();
           let bab = rep.access_token 
           let image = img
           let ite
           if (img === '' && pack_price === ''){
              ite = {price}
              setDone('Price Updated! refresh to see update')
           } else if (img === '' && price === ''){
            ite = {pack_price}
            setDone('Pack Price Updated, refresh to see update')
           } else {
          ite ={image}
        }
         try {
           let result = await fetch(`https://api.prestigedelta.com/productdetail/${index.id}/`, {
             method: 'PUT',
             headers: {
               'Content-Type': 'application/json',
               'accept': 'application/json',
               'Authorization': `Bearer ${bab}`
             },
             body: JSON.stringify(ite)
           });
                 if (result.status !== 200) {
             const errorResult = await result.json();
             setMessag(JSON.stringify(errorResult));
           } else {
              result =await result.json();
             setMessag(JSON.stringify(result.message))
             setFun(result)
              } 
         } catch (error) {
           // Handle fetch error
           console.error(error);
         };
       }
       
       useEffect(() => {
        if (img !== '') {
          aprod();
        }
      }, [img]);
      
      
    if(loading) {
         return(
         <p>Loading...</p>)} 
         let percent =  Math.round((((parseFloat(index.total_sales) /parseFloat(info[0].stock_value) ) * 100) + Number.EPSILON) * 100) / 100

    return(
        <ChakraProvider>
        <div>
        <Link to='/components/product'>
                 <i className="fa-solid fa-chevron-left bac"></i>
             </Link>
  <Heading size='md' m={3}>{index.name}</Heading>
  
  {index.image === ''?<div><Text m={2}>Upload product images for marketplace listing</Text>
  <Button colorScheme='blue' onClick={modal1.onOpen}>Add Image</Button></div>:
  <div><Box display='flex' justifyContent='center' alignItems='center'>
   <Image 
    boxSize='150px'
    objectFit='cover'
    src={`${index.image}`} alt={`${index.name}`} />
   </Box><br/>
   <Button onClick={modal1.onOpen} colorScheme='green'>Edit Image</Button>
   </div>
    }
    
  <SimpleGrid spacing={4} m={3} templateColumns='repeat(auto-fill, minmax(150px, 1fr))'>
  <Card height='' border='1px solid lightblue'>
    
    <Heading mt={3} size='xs' >Price of a Pack</Heading>
      
      <Text>{index.pack_price > 0 
    ? `₦${parseFloat(index.pack_price).toLocaleString('en-US')}` 
    : 'Price not set'}</Text>
      <div>
      <Button onClick={modal2.onOpen} mb={2} colorScheme='blue' variant='solid'>Edit Price</Button></div>
  </Card>
  <Card height='' border='1px solid lightblue'>
    
    <Heading mt={3} size='xs' >Price of item</Heading>
      
      <Text>{index.pack_price > 0 
    ? `₦${parseFloat(index.price).toLocaleString('en-US')}` 
    : 'Price not set'}</Text>
      <div>
      <Button onClick={modal3.onOpen} mb={2} colorScheme='blue' variant='solid'>Edit Price</Button></div>
  </Card>
  <Card height='90px' border='1px solid lightblue'>
    <CardHeader>
      <Heading size='xs'>Total Sales</Heading>
    </CardHeader>
      <Text>₦{(parseFloat(index.total_sales)).toLocaleString('en-US')}</Text>
  </Card>
  <Card height='90px' border='1px solid lightblue'>
    <CardHeader>
      <Heading size='xs'>No of packs</Heading>
    </CardHeader>
      <Text>{index.pack_count}</Text>
  </Card>
  <Card height='90px' border='1px solid lightblue'>
    <CardHeader mb={0}>
    <Heading size='xs' >No of Unit Items</Heading>
       </CardHeader>
      <Text>{index.item_count}</Text>
  </Card>
  <Card height='90px' border='1px solid lightblue'>
    <CardHeader mb={0}>
    <Heading size='xs' >Contr. to Revenue</Heading>
       </CardHeader>
      <Text>{percent}%</Text>
  </Card>
  <Card height='90px' border='1px solid lightblue'>
    <CardHeader mb={0}>
    <Heading size='xs' >Sales Value</Heading>
       </CardHeader>
      <Text>₦{parseFloat((index.sales_share)).toLocaleString('en-US')}</Text>
  </Card>
  <Card height='90px' border='1px solid lightblue'>
    <CardHeader mb={0}>
    <Heading size='xs' >Purchase Value</Heading>
       </CardHeader>
      <Text>₦{(parseFloat(index.price)).toLocaleString('en-US')}</Text>
  </Card>
  <Card height='90px' border='1px solid lightblue'>
    <CardHeader mb={0}>
    <Heading size='xs' >Sales per Month</Heading>
       </CardHeader>
      <Text>₦{(parseFloat(index.sold_per_month)).toLocaleString('en-US')}</Text>
  </Card>
  <Card height='90px' border='1px solid lightblue'>
    <CardHeader mb={0}>
    <Heading size='xs' >Sell Out Date</Heading>
       </CardHeader>
      <Text>{(new Date(index.sell_out_date).toLocaleString('en-GB'))}</Text>
  </Card>
  
</SimpleGrid>
<div>
<Button colorScheme='blue' variant='solid' onClick={sent}>Get Product Analysis</Button></div>
        </div>
        <Modal isOpen={modal1.isOpen} onClose={modal1.onClose}>
          <ModalOverlay />
          <ModalContent>
          
            <ModalHeader>Add Photo</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
      {fun === '' ? (
      <div>
      
            <Input type='file' placeholder='Add a photo' width={273} ml={9} onChange={handleFileChange}/><br />
       <br/>  
                {buttonVisible && (  <Button  colorScheme='blue' variant='solid' onClick={uploadFile}>Add</Button> 
                )}
      {!buttonVisible && <p>Processing...</p>}
            
            </div>) :
            <div >
          <div style={{ marginLeft:'5%', alignItems: 'center', justifyContent: 'center' }}>
          <img className='goo' src={good} alt="" />
          </div>
          <Heading fontSize='14px' className="hoo">{done}</Heading>  
      </div>}
      </ModalBody>
              </ModalContent>
      
            </Modal>
            <Modal isOpen={modal2.isOpen} onClose={modal2.onClose}>
          <ModalOverlay />
          <ModalContent>
          
            <ModalHeader>Edit Pack Price</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
      {fun === '' ? (
      <div>
      
            <Input type='number' placeholder='Set Pack price' width={273} ml={9} onChange={handlePack}/><br />
       <br/> 
                {buttonVisible && (  <Button  colorScheme='blue' variant='solid' onClick={aprod}>Add</Button> 
                )}
      {!buttonVisible && <p>Processing...</p>}
            
            </div>) :
            <div >
          <div style={{ marginLeft:'5%', alignItems: 'center', justifyContent: 'center' }}>
          <img className='goo' src={good} alt="" />
          </div>
          <Heading fontSize='14px' className="hoo">{done}</Heading>  
      </div>}
      </ModalBody>
              </ModalContent>
      
            </Modal>
            <Modal isOpen={modal3.isOpen} onClose={modal3.onClose}>
          <ModalOverlay />
          <ModalContent>
          
            <ModalHeader>Edit Price</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
      {fun === '' ? (
      <div> 
       <Input type='number' placeholder='Set Price for one Item' width={273} ml={9} onChange={handlePrice}/><br />
       <br/> 
                {buttonVisible && (  <Button  colorScheme='blue' variant='solid' onClick={aprod}>Set</Button> 
                )}
      {!buttonVisible && <p>Processing...</p>}
            
            </div>) :
            <div >
          <div style={{ marginLeft:'5%', alignItems: 'center', justifyContent: 'center' }}>
          <img className='goo' src={good} alt="" />
          </div>
          <Heading fontSize='14px' className="hoo">{done}</Heading>  
      </div>}
      </ModalBody>
              </ModalContent>
      
            </Modal>
        </ChakraProvider>
    )
}
export default ProDe
import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { Card, CardContent, CardMedia, Typography, Grid, Container } from '@mui/material';
import {
  ChakraProvider,
  Button,
  Stack,
  CardBody,
  Heading,
  Text,
  Image,
  Radio,
  RadioGroup,
  IconButton,
  Box, Spinner
} from '@chakra-ui/react';
 

const OrderList = () => {
const [pin, setPin] = useState('');
const [messag, setMessag] = useState('')
const [message, setMessage] = useState('')
const location = useLocation()
const list = location.state.mata

let tok= JSON.parse(localStorage.getItem("user-info"));
const terms = (tok) => {
  let refreshval;
  if (tok === null || typeof tok === 'undefined') {
    refreshval = 0;
  } else {
    refreshval = tok.refresh_token;
  }
 
  return refreshval;
};
let refresh = terms(tok)

const handleOtpRequest = async () => {
  let items = { refresh };
  let response = await fetch('https://api.prestigedelta.com/refreshtoken/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(items)
  });
  let data = await response.json();
  let accessToken = data.access_token;
  let saleId = list.id
  let payload = { sale_id: saleId };

  try {
    let result = await fetch('https://api.prestigedelta.com/businessbopis/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload)
    });

    if (result.status !== 200) {
      const errorResult = await result.json();
      setMessage(JSON.stringify(errorResult));
    } else {
      result = await result.json();
      setMessage(result.message);
    }
  } catch (error) {
    console.error(error);
  }
};

async function otp() {
          
    let items ={refresh}
     let rep = await fetch ('https://api.prestigedelta.com/refreshtoken/',{
         method: 'POST',
         headers:{
           'Content-Type': 'application/json',
           'accept' : 'application/json'
      },
      body:JSON.stringify(items)
     });
     rep = await rep.json();
     let bab = rep.access_token 
     
     let otp = pin
     let ite = { otp };
   try {
     let result = await fetch(`https://api.prestigedelta.com/bopis/${list.id}/`, {
       method: 'PUT',
       headers: {
         'Content-Type': 'application/json',
         'accept': 'application/json',
         'Authorization': `Bearer ${bab}`
       },
       body: JSON.stringify(ite)
     });
           if (result.status !== 200) {
       const errorResult = await result.json();
       setMessag(JSON.stringify(errorResult));
     } else {
        result =await result.json();
       setMessag(result.message)
       
        } 
   } catch (error) {
     // Handle fetch error
     console.error(error);
   };
 }

const ItemCard = ({ item }) => (
  <Card>
    <CardContent>
      <Typography gutterBottom variant="h6" component="div">
        {item.product_name}
      </Typography>
      <Typography gutterBottom variant="body2" component="div">
        {item.quantity_type} quantity: {item.sold_quantity}
      </Typography>
      <Typography variant="body2" color="text.secondary">
      ₦{parseFloat(item.sold_amount).toLocaleString('en-US')}
      </Typography>
    </CardContent>
  </Card>
);
  
    return(
        <div>
         <Link to='/components/order'>
          <i className="fa-solid fa-chevron-left bac"></i>
        </Link>
            
            <Grid container spacing={2} mt={1} m={2}>
  {list.sold_products && list.sold_products.length > 0 ? (
    list.sold_products.map((item, index) => (
      <Grid item  sm={3} md={2} key={index}>
        <ItemCard item={item} />
      </Grid>
    ))
  ) : (
    <Typography>No products found</Typography>
  )}
</Grid>


<ChakraProvider>
    <Heading fontSize='19px'>Total Payment:₦{parseFloat(list.amount).toLocaleString('en-US')} </Heading>
    {messag === ''?
<div>    <Text>Customer's Pick Up Date: {new Date(list.pick_up_time).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</Text><br/>
<Text>Customer's Phone Number: {(list.username).replace('234', '0')}</Text>
                    

             <br/>
{message === '' ? (             <Button colorScheme='blue' variant='solid' onClick={handleOtpRequest}>Accept Order</Button>
              
            ):
            <div>
                        <Text m={2}>Input OTP to complete transaction and receive payment</Text>
            <div style={{display:'flex', justifyContent:'center'}}>
              <OtpInput  
                 value={pin}
                 onChange={setPin}
                  numInputs={6}
                 renderSeparator='-'
                 renderInput={(props) => <input {...props }  className='tote' />}
                /></div>
                <Button colorScheme='blue' variant='solid' onClick={otp}>Complete Order</Button>
                <Text>{messag}</Text></div>}
              
                <Text>{message}</Text></div>
              : <Heading>{messag}</Heading> }

            </ChakraProvider>
        </div>
    )
}
export default OrderList
import React, { useState, useEffect } from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {ChakraProvider, Card, Button, Box, Image, Stack, CardBody, Heading, Text, TabPanels } from '@chakra-ui/react';
import { Nav } from './nav.jsx'
import { Tabs, TabList,  Tab, TabPanel } from '@chakra-ui/react'

const Order = () => {
    const [info, setInfo] = useState('')
    const [data, setData] = useState('')
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    let tok= JSON.parse(localStorage.getItem("user-info"));
    const terms = (tok) => {
      let refreshval;
      if (tok === null || typeof tok === 'undefined') {
        refreshval = 0;
      } else {
        refreshval = tok.refresh_token;
      }
     
      return refreshval;
    };
    let refresh = terms(tok)
    

    const fetchInfo = async () => {
        let item ={refresh}
        let rep = await fetch ('https://api.prestigedelta.com/refreshtoken/',{
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              'accept' : 'application/json'
         },
         body:JSON.stringify(item)
        });
        rep = await rep.json();
        let bab = rep.access_token
      let response = await fetch(`https://api.prestigedelta.com/customerbopis/`,{
      method: "GET",
      headers:{'Authorization': `Bearer ${bab}`},
      })
      
      if (response.status === 401) {
        navigate('/components/login');
      } else {  
      response = await response.json();}
      setInfo(response)
      setLoading(false)
      }
           useEffect(() => {
        fetchInfo()
        }, [])

        const fetchData = async () => {
            let item ={refresh}
            let rep = await fetch ('https://api.prestigedelta.com/refreshtoken/',{
                method: 'POST',
                headers:{
                  'Content-Type': 'application/json',
                  'accept' : 'application/json'
             },
             body:JSON.stringify(item)
            });
            rep = await rep.json();
            let bab = rep.access_token
          let response = await fetch(`https://api.prestigedelta.com/businessbopis/`,{
          method: "GET",
          headers:{'Authorization': `Bearer ${bab}`},
          })
          
          if (response.status === 401) {
            navigate('/components/login');
          } else {  
          response = await response.json();}
          setData(response)
          
          }
               useEffect(() => {
            fetchData()
            }, [])
    
            const transfer= (index)=>{
            
                 const mata = data[index]
                    navigate('/components/orderlist', {state:{mata}})
               }
   if(loading) {
            return(
            <p>Loading...</p>)}
            else if (info.length < 1 && data.length < 1)        
                return(
            <ChakraProvider>
            <div>
            <Nav/>
             <p>No order made yet</p>
            </div>
            </ChakraProvider>  )
    return(
        <div>
        <Nav/>
            <ChakraProvider>
            <Tabs isFitted variant='enclosed'>
<TabList mb='1em'>
    <Tab>Request Received </Tab>
    <Tab>Orders Made</Tab>
  </TabList>
  
  <TabPanels>
    <TabPanel p={0}>
    
            {data.map((obj, index) => (
                <Card  key={index} m={3} boxShadow='6px 3px green' backgroundColor='lightgreen' >
                    <CardBody>
                     <Text>Amount: ₦{parseFloat(obj.amount).toLocaleString('en-US')}</Text>
                     <Text>Customer's Pick Up Date: {new Date(obj.pick_up_time).toLocaleString('en-GB')}</Text>
                     <Text>Customer's Phone Number: {(obj.username).replace('234', '0')}</Text>
                     <Heading fontSize='14px'></Heading>
            {obj.sold_products.map((prod, inde) =>(
                <div key={inde}>
                <Text>{prod.product_name}: {prod.sold_quantity}</Text>
                <Text>{prod.quantity_type}</Text>
                <Text>Purchased Amount: ₦{parseFloat(prod.sold_amount).toLocaleString('en-US')}</Text>
                </div>
            ))}
            <Button onClick={() => transfer(index)} colorScheme='green'>Accept Order</Button>
                    </CardBody>
                </Card>
            ))}
            </TabPanel>
<TabPanel>
{info.map((obj, index) => (
                <Card key={index} m={3} boxShadow='6px 3px grey' backgroundColor='lightblue' color=''>
                    <CardBody>
                    <Heading fontSize='16px'>Order made to {obj.business_name}</Heading>
                     <Text>Amount: ₦{(parseFloat(obj.amount)).toLocaleString('en-US')}</Text>
                     {obj.otp_code === null ?<Text>OTP:Merchant is yet to generate OTP</Text> :<Heading fontSize='14px'>OTP: {obj.otp_code}</Heading>}
            {obj.sold_products.map((prod, inde) =>(
                <div key={inde}>
                <Text>{prod.product_name}: {prod.sold_quantity}</Text>
                <Text>{prod.quantity_type}</Text>
                <Text>Purchased Amount: ₦{(parseFloat(prod.sold_amount)).toLocaleString('en-US')}</Text>
                </div>
            ))}
                    </CardBody>
                </Card>
            ))}
            
</TabPanel>
</TabPanels>
</Tabs>
            </ChakraProvider>
        </div>
    )

}
export default Order